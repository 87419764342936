<template>
  <div class="questionnaire-page">
    <div class="question-content">
      <Content class="question">
        <Question
          v-if="currentQuestion"
          :on-boarding-questionnaire="onBoardingQuestionnaire"
          :thanks-u2-page="thanksU2Page"
          @completed-typing="completedTyping($event)"
          :question="onBoardingQuestionnaire ? onBoardingQuestion : currentQuestion.text"/>
        <div v-if="!thanksU2Page && currentQuestion" class="question__select-result">
          <div class="pt-9">
            <QuestionSlider
              :is-explain-page="onBoardingQuestionnaire"
              @update:answer="updateAnswer"
              :clickedOnSlider="clickedOnSlider"
              :answer="answer"
              @selected-question="checkOnBoardingPage"
              @click-slider="clickToSlider"
              @cancel-next-question="cancelNextQuestion"
              :read-only="finishTyping"
              :is-next-question="isNextQuestion"
            />
          </div>
        </div>
      </Content>
    </div>
    <LoaderGetResult
      :image="congratulations"
      :title="'HERE ARE YOUR'"
      :subTitle="'RESULTS'"
      :text-button="'TAP TO CONTINUE'"
      :time="30"
      @next-step="showResultPage"
      v-if="!completedResult">
    </LoaderGetResult>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import LoaderGetResult from '@innerworks_ds/innerworks-storybook-main/src/stories/Loader/LoaderGetResult/LoaderGetResult.vue';
import { mapGetters } from 'vuex';
import QuestionSlider from '@components/Slider/QuestionSlider.vue';
import userBehave from '@constants/userBehave';
import congratulations from '@assets/congratulations.svg';
import Question from '@innerworks_ds/innerworks-storybook-main/src/stories/Questions/Question.vue';
import differentsServices from '@helpers/differentsServices';

export default {
  components: {
    QuestionSlider,
    Question,
    LoaderGetResult,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    startFromQuestion: {
      type: String,
    },
  },
  data() {
    return {
      congratulations,
      onBoardingQuestionnaire: false,
      currentQuestion: null,
      currentIndex: 0,
      answer: 0,
      formData: {},
      completedResult: true,
      clickedOnSlider: false,
      debouncedHandler: null,
      isOpenOwnFeedBackErrorModal: false,
      finishTyping: true,
      showCheckPointPage: false,
      isNextQuestion: false,
      onBoardingQuestion: 'A marked score can’t be undone',
      imageCheckPointScreen: '',
      nameCheckPointScreen: '',
      textUnderImage: '',
      behaveQuestion: [],
      timeBetweenAnswers: null,
      delayTimeOut: null,
      titleName: '',
      thanksU2Page: false,
      deplayForAnimation: false,
      howQuestionBeforeCheckPointScreen: 30,
      getDeviceType: {},
      behaveQuestionDuringTyping: [],
      typingQuestionTime: null,
    };
  },
  watch: {
    questions: {
      handler(value) {
        if (value && value.length) {
          // eslint-disable-next-line
          for (const property in value) {
            this.howQuestionBeforeCheckPointScreen = value.length;
            if (value[property].qid === this.startFromQuestion) {
              this.currentIndex = +property;
              this.$store.dispatch('quiz/updateQuestionnaireData', {
                currentIndex: this.currentIndex,
                countOfQuestion: this.questions.length,
                showProgressBar: true,
                numberCheckPointScreen: 0,
                questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
              });
            }
          }

          if (this.currentIndex === 0) {
            this.onBoardingQuestionnaire = true;
            this.$store.dispatch('quiz/updateQuestionnaireData', {
              currentIndex: this.currentIndex,
              countOfQuestion: this.questions.length,
              showProgressBar: false,
              numberCheckPointScreen: 0,
              questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
            });
          }
          this.howQuestionBeforeCheckPointScreen = value.length;
          this.currentQuestion = value[this.currentIndex];
        }
      },
      immediate: true,
    },
    answer: {
      handler(value) {
        this.$store.dispatch('quiz/saveLastAnswer', value);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      getLastAnswer: 'quiz/getLastAnswer',
      getGeneralSurveyData: 'surveyData/getGeneralSurveyData',
    }),
    getTickSize() {
      return Math.abs(Math.round(this.answer) * 5) + 60;
    },
    isAuth() {
      return this.getProfile.token;
    },
    countCheckpointScreens() {
      return this.currentIndex / this.howQuestionBeforeCheckPointScreen;
    },
  },
  mounted() {
    this.$store.dispatch('showComponent/showHeaderMain');
    this.behaveUserDuringTypeTheQuestion();
    this.getUserBrowserData();
  },
  methods: {
    getUserBrowserData() {
      const clientData = differentsServices.getClientData();
      this.getDeviceType = {
        deviceType: clientData && clientData.deviceType ? clientData.deviceType : 'computer',
        browser: clientData.browser,
        browserVersion: clientData.browserVersion,
        operationSystem: clientData.operationSystem,
      };
    },
    behaveUserDuringTypeTheQuestion() {
      // eslint-disable-next-line func-names
      document.querySelector('#app').onclick = (event) => {
        if (this.finishTyping) {
          // eslint-disable-next-line no-param-reassign
          event = event || window.event;
          const currentSeconds = this.getMilliseconds();
          const calculationResult = ((currentSeconds - this.typingQuestionTime) / 1000);

          this.behaveQuestionDuringTyping.push({
            timeOfClick: calculationResult,
            positionOfClickX: event.pageX,
            positionOfClickY: event.pageY,
          });
        }
      };
    },
    showResultPage() {
      this.$router.push('result');
    },
    completedTyping(value) {
      this.finishTyping = value;
      if (value === false) {
        this.timeBetweenAnswersMilliseconds = this.getMilliseconds();
      }
    },
    updateAnswer(answer) {
      this.clickedOnSlider = true;
      this.answer = answer;
      if (this.clickedOnSlider && !this.onBoardingQuestionnaire) {
        this.updateUserAnswerBehave(answer);
      }
    },
    clickToSlider() {
      this.clickedOnSlider = true;
    },
    getMilliseconds() {
      return new Date().getTime();
    },
    cancelNextQuestion() {
      if (this.debouncedHandler) {
        this.debouncedHandler.cancel();
      }
    },
    checkOnBoardingPage(answer) {
      if (this.onBoardingQuestionnaire) {
        this.showOnBoardingPage();
      } else {
        this.onSelectedQuestion(answer);
      }
    },
    onSelectedQuestion(answer) {
      this.updateUserAnswerBehave(answer);
      this.debouncedHandler = debounce(async () => {
        this.isNextQuestion = true;
        this.finishTyping = true;
        this.formData[this.questions[this.currentIndex].qid] = answer;

        const uniqueId = this.generateUniqueId();
        await this.userBehaveSentEvent();
        this.$api.questionnaireCutVersion.savePartialAnswers({
          [this.questions[this.currentIndex].qid]: answer,
        }, {
          u2Id: uniqueId,
          self: true,
        }, uniqueId, this.getGeneralSurveyData.questionnaireCutVersionId)
          .then(() => {
            this.currentIndex += 1;
            this.$store.dispatch('quiz/updateQuestionnaireData', {
              currentIndex: this.currentIndex,
              countOfQuestion: this.questions.length,
              showProgressBar: true,
              numberCheckPointScreen: 0,
              questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
            });
            setTimeout(async () => {
              this.isNextQuestion = false;
              await this.nextQuestion();

              if (this.currentIndex === 0) {
                await this.$store.dispatch('showComponent/turnOffHeader');
              }
            }, 500);
          });
      }, 300);

      return this.debouncedHandler();
    },
    generateUniqueId() {
      const generalSurveyData = this.getGeneralSurveyData;
      let uniqueId;

      if (generalSurveyData.numberOfTimesCompetedQuiz === 0) {
        uniqueId = generalSurveyData.questionnaireId1;
      } else {
        uniqueId = generalSurveyData.questionnaireId2;
      }

      return uniqueId;
    },
    updateUserAnswerBehave(answer) {
      const currentSeconds = this.getMilliseconds();
      const calculationResult = ((currentSeconds - this.timeBetweenAnswersMilliseconds) / 1000);
      const lengthBehaveQuestion = this.behaveQuestion.length - 1;
      let changeTime = calculationResult;
      if (this.behaveQuestion.length === 0 && calculationResult <= 0.2) {
        changeTime = 0;
      }

      if (this.behaveQuestion.length !== 0
        && this.behaveQuestion[lengthBehaveQuestion].answer === answer) {
        this.behaveQuestion[lengthBehaveQuestion].timeQuestions += (changeTime / 1000.0);
      } else {
        this.behaveQuestion.push({
          answer,
          timeQuestions: changeTime,
        });
      }

      this.timeBetweenAnswersMilliseconds = this.getMilliseconds();
    },
    showThanksPage() {
      this.debouncedHandler = debounce(() => {
        this.onBoardingQuestion = `Let’s find ${this.getInvitationData.name}'s strengths!`;
        this.isNextQuestion = true;
        this.finishTyping = true;
        setTimeout(() => {
          this.thanksU2Page = true;
          this.isNextQuestion = false;
          this.answer = 0;
          this.clickedOnSlider = false;
        }, 400);
      }, 300);

      return this.debouncedHandler();
    },
    showOnBoardingPage() {
      const timer = 300;
      this.debouncedHandler = debounce(() => {
        this.isNextQuestion = true;
        this.finishTyping = true;

        setTimeout(() => {
          this.isNextQuestion = false;
          this.answer = 0;
          this.clickedOnSlider = false;

          setTimeout(() => {
            this.onBoardingQuestionnaire = false;
            setTimeout(() => {
              this.thanksU2Page = false;
            }, 300);
            this.$store.dispatch('quiz/updateQuestionnaireData', {
              currentIndex: this.currentIndex,
              countOfQuestion: this.questions.length,
              showProgressBar: true,
              numberCheckPointScreen: 0,
              questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
            });
          }, 400);
        }, 500);
      }, timer);

      return this.debouncedHandler();
    },
    async nextQuestion() {
      if (this.currentIndex >= this.questions.length) {
        this.answer = 0;
        this.currentIndex = 0;
        this.currentQuestion = null;
        this.loading = true;
        await this.saveAnswer();
        this.completedResult = false;
        this.loading = false;
      } else {
        this.answer = 0;
        this.currentQuestion = this.questions[this.currentIndex];
        this.typingQuestionTime = this.getMilliseconds();
        this.clickedOnSlider = false;
      }
    },
    userBehaveSentEvent() {
      let eventName = userBehave.answerToQuestionCutVersion + this.currentIndex;

      if (this.getGeneralSurveyData.numberOfTimesCompetedQuiz === 0) {
        eventName = userBehave.answerToQuestionCutVersion + this.currentIndex;
      } else {
        eventName = userBehave.answerToQuestionSecondTimeCutVersion + this.currentIndex;
      }

      const eventDuringTypingData = {
        widthOfScreen: document.documentElement.clientWidth,
        heightOfScreen: document.documentElement.clientHeight,
        ...this.getDeviceType,
      };

      this.$api.userBehaveSite.saveUserEventDuringTypingQuestionsCutVersion(eventName,
        this.getGeneralSurveyData.prolificId, eventDuringTypingData,
        this.behaveQuestionDuringTyping)
        .then(() => {
          this.behaveQuestionDuringTyping = [];
        });


      this.$api.userBehaveSite.saveSiteEventCutVersion(
        eventName,
        this.getGeneralSurveyData.prolificId,
        this.behaveQuestion,
      ).then(() => {
        this.behaveQuestion = [];
      });
    },
    async saveAnswer() {
      const uniqueId = this.generateUniqueId();
      const prolificID = this.getGeneralSurveyData.prolificId;
      return this.$api.questionnaireCutVersion.saveResult(this.formData, uniqueId, prolificID,
        this.getGeneralSurveyData.questionnaireCutVersionId)
        .then(() => {
          this.$store.dispatch('surveyData/updateSurveyData', prolificID);
        }).catch(() => {
          this.$store.dispatch('surveyData/updateSurveyData', prolificID);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.text-center {
  height: 125px;
}
.animation-checkpoint-page {
  position: absolute;
  display: block;
  z-index: 100;
  top: 0;
}

.circle-enter-active {
  display: block;
  animation: 3000ms circle forwards;
}

.circle-with-delay-leave-active {
  display: block;
  animation: 3000ms circle reverse forwards;
  animation-delay: 900ms;
}

.circle-without-delay-leave-active {
  display: block;
  animation: 2000ms circle reverse forwards;
}

@keyframes circle {
  from {
    clip-path: circle(0% at var(--w) var(--h));
  }
  to {
    clip-path: circle(110%);
  }
}

.question-content {
  box-sizing: border-box;
  overflow-y: hidden;
  z-index: 100;
}

.container {
  background: transparent;
}

::v-deep.question {
  .container {
    background: transparent;
  }

  @media (max-width: 330px) {
    .question__select-result {
      padding-top: 40px;

      .v-slider__thumb-label {
        bottom: 100px !important;
      }

      .info-use {
        margin-top: 0 !important;
      }
    }
    .question__content {
      font-size: 22px !important;
    }
  }
  @media (max-width: 380px) {
    .current-question {
      margin-top: 50px;
    }
    .question__select-result {
      padding-top: 40px;

      .v-slider__thumb-label {
        bottom: 100px !important;
      }

      .info-use {
        margin-top: 0 !important;
      }
    }
    .question__content {
      font-size: 28px;
    }
  }
  @media (max-height: 600px) {
    .question__select-result {
      .info-use {
        margin-top: -10px !important;
      }
    }
  }

  .question__select-result {
    .info-use {
      font-family: $newDefaultFont;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #222222;
    }

    .info-text {
      opacity: 0.3;
      margin-top: 21px;
    }
  }
}
</style>
