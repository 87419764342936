<template>
  <div class="questionnaire-inner"
       :style="{background: getLastAnswer.color}">
    <div class="header-initial">
      <QuestionnaireCutVersion
        :questions="questions"
        :startFromQuestion="startQuestion"
      />
    </div>
  </div>
</template>

<script>
import QuestionnaireCutVersion from '@components/QuestionnaireCutVersion/QuestionnaireCutVersion.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Main.vue',
  components: {
    QuestionnaireCutVersion,
  },
  data: () => ({
    questions: [],
    startQuestion: null,
  }),
  computed: {
    ...mapGetters({
      getLastAnswer: 'quiz/getLastAnswer',
      getGeneralSurveyData: 'surveyData/getGeneralSurveyData',
    }),
  },
  mounted() {
    this.$api.questionnaireCutVersion.getSelfQuestionnaireNew(this.generateUniqueId(),
      this.getGeneralSurveyData.questionnaireCutVersionId)
      .then((x) => {
        this.startQuestion = x.startFromQuestionId;
        this.questions = x.questionList2;
      });
  },
  methods: {
    generateUniqueId() {
      const generalSurveyData = this.getGeneralSurveyData;
      let uniqueId;

      if (generalSurveyData.numberOfTimesCompetedQuiz === 0) {
        uniqueId = generalSurveyData.questionnaireId1;
      } else {
        uniqueId = generalSurveyData.questionnaireId2;
      }

      return uniqueId;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-initial {
  margin: -2px 0 -2px 0;
  max-width: 480px !important;
  align-self: center;
  height: calc(100% - 72px);
}
.questionnaire-inner {
  background-color: rgba(120, 17, 201, 0.05);
  height: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s;
}
</style>
